// import React from 'react';

export const CurrencyFormat = (props: { value: any }) => {
  const { value } = props
  const newValue = Math.ceil(value).toString()

  if (newValue.length <= 3) {
    return <>{newValue},00</>
  }

  return <>{newValue.slice(0, 1) + '.' + newValue.slice(1)},00</>
}
