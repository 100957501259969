import React, { useRef, useEffect, useState } from 'react'
import { Alert, Card, Col, Form, Row } from 'react-bootstrap'
import ReactCSSTransitionGroup, { Transition } from 'react-transition-group'
import { CurrencyFormat, FormInput } from '../components'
import { useScript, UseSendFormData } from '../hooks'
import { FormDataInterface, State, EconomyInterface } from '../types'
export default function Home() {
  useScript('assets/js/custom.js')
  const headerRef = useRef<HTMLElement>(null)
  const [logoColored, updateLogoColored] = useState(false)
  // % de economia
  const montlyEconomyPercentage = 0.1
  const initialMonthlyCost = 300

  const [formErrors, updateFormErrors] = useState<{ [key: string]: string }>({})
  const defaultFormData = {
    name: '',
    company: '',
    email: '',
    cnpjCpf: '',
    phone: '',
    zipCode: '',
    adress: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    isCompany: false,
    monthlyCost: initialMonthlyCost,
    state: {
      name: 'Paraná',
      uf: 'PR',
    },
  }

  const states: State[] = [
    // {
    //   name: 'Acre',
    //   uf: 'AC',
    // },
    // {
    //   name: 'Alagoas',
    //   uf: 'AL',
    // },
    // {
    //   name: 'Amapá',
    //   uf: 'AP',
    // },
    // {
    //   name: 'Amazonas',
    //   uf: 'AM',
    // },
    // {
    //   name: 'Bahia',
    //   uf: 'BA',
    // },
    // {
    //   name: 'Ceará',
    //   uf: 'CE',
    // },
    // {
    //   name: 'Espírito Santo',
    //   uf: 'ES',
    // },
    // {
    //   name: 'Goiás',
    //   uf: 'GO',
    // },
    // {
    //   name: 'Maranhão',
    //   uf: 'MA',
    // },
    // {
    //   name: 'Mato Grosso',
    //   uf: 'MT',
    // },
    // {
    //   name: 'Mato Grosso do Sul',
    //   uf: 'MS',
    // },
    // {
    //   name: 'Minas Gerais',
    //   uf: 'MG',
    // },
    // {
    //   name: 'Pará',
    //   uf: '	PA',
    // },
    // {
    //   name: 'Paraíba',
    //   uf: 'PB',
    // },
    {
      name: 'Paraná',
      uf: 'PR',
    },
    // {
    //   name: 'Pernambuco',
    //   uf: 'PE',
    // },
    // {
    //   name: 'Piauí',
    //   uf: 'PI',
    // },
    // {
    //   name: 'Rio de Janeiro',
    //   uf: 'RJ',
    // },
    // {
    //   name: 'Rio Grande do Norte',
    //   uf: 'RN',
    // },
    // {
    //   name: 'Rio Grande do Sul',
    //   uf: 'RS',
    // },
    // {
    //   name: 'Rondônia',
    //   uf: 'RO',
    // },
    // {
    //   name: 'Roraima',
    //   uf: 'RR',
    // },
    // {
    //   name: 'Santa Catarina',
    //   uf: 'SC',
    // },
    // {
    //   name: 'São Paulo',
    //   uf: 'SP',
    // },
    // {
    //   name: 'Sergipe',
    //   uf: 'SE',
    // },
    // {
    //   name: 'Tocantins',
    //   uf: 'TO',
    // },
  ]

  const [formData, updateFormData] =
    useState<FormDataInterface>(defaultFormData)

  const [economy, updateEconomy] = useState<EconomyInterface>({
    monthly: initialMonthlyCost * montlyEconomyPercentage,
    anual: initialMonthlyCost * montlyEconomyPercentage * 12,
  })

  const [floatingSimulator, setFloatingButtonsVisibility] = useState(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const listenToScroll = () => {
    let heightToHideFrom = 500
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    if (winScroll > heightToHideFrom) {
      floatingSimulator && // to limit setting state only the first time
        setFloatingButtonsVisibility(false)
    } else {
      setFloatingButtonsVisibility(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [listenToScroll])

  const handleUpdateFormData = (field: string, value: any) => {
    const newFormData = {
      ...formData,
      [field]: value,
    }
    updateFormData(newFormData)
  }

  const handleUpdateSimulator = (value: any) => {
    updateEconomy({
      monthly: value * montlyEconomyPercentage,
      anual: value * montlyEconomyPercentage * 12,
    })
    handleUpdateFormData('monthlyCost', value)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef?.current?.classList.contains('background-header')) {
        updateLogoColored(true)
      } else {
        updateLogoColored(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showvalidationError, setShowValidationError] = useState(false)
  const successMessage = (
    <>
      <b>Pronto</b>, você deu o primeiro passo, daqui para frente é com a gente,{' '}
      <b>entraremos em contato em breve!</b>
    </>
  )
  const errorMessage = <>Os campos com * são de preenchimento obrigatório!</>
  const errorSendMessage = (
    <>
      Houve um erro ao enviar os seus dados. Por favor, tente novamente mais
      tarde!
    </>
  )

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    handleUpdateFormData(field, e.target.value)
  }
  const handleSelectState = (uf: string) => {
    handleUpdateFormData(
      'state',
      states.find((s) => s.uf === uf)
    )
  }
  const [loading, setLoading] = useState(true)
  const nodeRef = useRef(null)

  const handleOnSubmit = async (e: any) => {
    e.preventDefault()
    if (isValid()) {
      setLoading(true)
      const formSent = await UseSendFormData({
        ...formData,
        state: formData.state?.uf || '',
      })
      if (formSent) {
        setShowSuccess(true)
        setTimeout(() => setShowSuccess(false), 10000)
        updateFormData(defaultFormData)
        setLoading(false)
      } else {
        setShowError(true)
        setTimeout(() => setShowError(false), 10000)
        setLoading(false)
      }
    }
  }

  const isValid = (): boolean => {
    let errors: { [key: string]: string } = {}
    if (formData.name.length < 3) {
      errors = {
        ...errors,
        name: 'O campo Nome é obrigatório',
      }
    }

    if (formData.email.length < 3) {
      errors = {
        ...errors,
        email: 'O campo Email é obrigatório',
      }
    }

    if (formData.phone.length < 3) {
      errors = {
        ...errors,
        phone: 'O campo Telefone é obrigatório',
      }
    }

    if (formData.city.length < 3) {
      errors = {
        ...errors,
        city: 'O campo Cidade é obrigatório',
      }
    }

    updateFormErrors(errors)
    const containErrors = Object.keys(errors).length > 0
    if (containErrors) {
      setShowValidationError(true)
      setTimeout(() => setShowValidationError(false), 10000)
    } else {
      setShowValidationError(false)
    }
    return !containErrors
  }

  useEffect(() => {
    const onPageLoad = () => {
      setLoading(false)
    }

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad()
    } else {
      window.addEventListener('load', onPageLoad)
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad)
    }
  }, [])

  return (
    <>
      {/* ***** floating-simulator Start ******/}
      {!floatingSimulator && (
        <>
          <a href="#welcome" className="floating-button floating-button-first main-button">
            <i className="fa-solid fa-arrow-up"></i>
          </a>
          <a href="https://wa.me/55041991684676" target={'_blank'} className="floating-button main-button">
            <i className="fa-brands fa-whatsapp"></i>
          </a>
        </>
      )}
      {/* ***** floating-simulator End ******/}

      {/* ***** Preloader Start ******/}
      {/* <div id="preloader">
        <div className="jumper">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div> */}
      <Transition nodeRef={nodeRef} in={loading} timeout={300}>
        <div
          ref={nodeRef}
          id="react-preloader"
          className={loading ? 'react-preloader-on' : ''}
        >
          <div className="jumper">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </Transition>
      {/* ***** Preloader End ******/}

      {/* ***** Header Area Start ******/}
      <header ref={headerRef} className="header-area header-sticky">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="main-nav">
                {/* ***** Logo Start ******/}
                <a href="#welcome" className="logo">
                  <img
                    src={
                      logoColored
                        ? 'assets/images/logo.png'
                        : 'assets/images/logo-white.png'
                    }
                    height="88"
                    alt="I-COOP Logo"
                  />
                </a>
                {/* ***** Logo End ******/}
                {/* ***** Menu Start ******/}
                <ul className="nav">
                  <li className="scroll-to-section">
                    <a href="#welcome" className="active">
                      Home
                    </a>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#section1">Sobre</a>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#about">Como Funciona</a>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#services">Benefícios</a>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#frequently-question">Dúvidas Frequentes</a>
                  </li>
                  {/* <li className="submenu">
                    <a href="javascript:;">Drop Down</a>
                    <ul>
                      <li><a href="">Sobre Nós</a></li>
                      <li><a href="">Features</a></li>
                      <li><a href="">FAQ's</a></li>
                      <li><a href="">Blog</a></li>
                    </ul>
                  </li>*/}
                  <li className="scroll-to-section">
                    <a href="#contact-us">Solicitar Proposta</a>
                  </li>
                </ul>
                <button className="menu-trigger">
                  <span>Menu</span>
                </button>
                {/* ***** Menu End ******/}
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* ***** Header Area End ******/}

      {/* ***** Welcome Area Start ******/}
      <div className="welcome-area" id="welcome">
        {/* ***** Header Text Start ******/}
        <div className="header-text">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
                <div className="left-text text-box">
                  <h1>Energia por assinatura</h1>
                  <p>
                    Energia verde com <strong>até 20% de desconto</strong>{' '}
                    mensal!
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
                <Card className="simulator">
                  <Row>
                    <Col>
                      <h4>Simule o desconto na sua energia:</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="d-flex justify-content-between">
                        <span>Valor médio da fatura mensal: </span>
                        <b>
                          R$ <CurrencyFormat value={formData.monthlyCost} />
                        </b>
                      </p>
                      <Form.Range
                        min={300}
                        max={2000}
                        value={formData.monthlyCost}
                        step={25}
                        onChange={(e) => handleUpdateSimulator(e.target.value)}
                      ></Form.Range>
                    </Col>
                  </Row>
                  {/* <Row className="mt-3 mb-3">
                    <Col sm={7} className="d-flex justify-content-between">
                      <p>Em qual estado você mora?</p>
                    </Col>
                    <Col sm={5}>
                      <Form.Select>
                        {states.map((state, i) => {
                          return (
                            <option
                              selected={formData.state?.uf === state.uf}
                              key={i}
                              value={state.uf}
                            >
                              {state.name}
                            </option>
                          )
                        })}
                      </Form.Select>
                    </Col>
                  </Row> */}
                  <div className="divider"></div>
                  <div className="result text-center">
                    <h4>Economia anual de até:</h4>
                    <h1>
                      <span className="badge bg-success">
                        R$ <CurrencyFormat value={economy.anual} />
                      </span>
                    </h1>
                    <small>
                      Representa um valor de desconto de{' '}
                      <b>
                        R$ <CurrencyFormat value={economy.monthly} /> mensais
                      </b>
                    </small>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
        {/* ***** Header Text End ******/}
      </div>
      {/* ***** Welcome Area End ******/}

      {/* ***** Welcome Area Start ******/}
      <section className="section" id="section1">
        <div className="container">
          <div className="row">
            <div className="left-text col-lg-7 col-md-12 col-sm-12 mobile-bottom-fix">
              <div className="left-heading">
                <h5>Quem somos</h5>
              </div>
              <p>Somos a I-COOP, sua cooperativa de energia!</p>
              <p>
                Nosso modelo de negócios democratiza o acesso a energias
                renováveis através de sistema cooperativo com energia por
                assinatura.
              </p>
              <p>
                Acreditamos que o modelo de energia cooperativista é a chave
                para a mudança socioambiental, pois é construído através da
                união de pessoas movidas pela inovação e com foco no
                desenvolvimento sustentável.
              </p>
              <p>
                Vamos juntos construir um modelo energético mais justo,
                democrático e limpo!
              </p>
            </div>
            <div className="right-image col-lg-5 col-md-12 col-sm-12 mobile-bottom-fix-big">
              <img
                src="assets/images/quem-somos.png"
                className="rounded img-fluid d-block mx-auto"
                alt="App"
              />
            </div>
          </div>
        </div>
      </section>
      {/* ***** Features Small End ******/}

      {/* ***** Welcome Area Start ******/}
      <section className="section" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12 left-image">
              <img
                src="assets/images/left-image.png"
                className="rounded img-fluid d-block mx-auto"
                alt="App"
              />
            </div>
            <div className="right-text col-lg-5 col-md-12 col-sm-12 mobile-top-fix">
              <div className="left-heading">
                <h5>Energia por assinatura</h5>
              </div>
              <div className="left-text">
                <p>
                  É um serviço por adesão. O consumidor recebe créditos de
                  energia de fonte renovável com descontos de até 20%, sem
                  precisar fazer investimentos, sem obras, sem fidelidade
                  contratual e 100% online.
                </p>
              </div>
              <div className="left-heading">
                <h5>Como funciona?</h5>
              </div>
              <div className="left-text">
                <p>
                  <b>1.</b> Nossa Fazenda Solar produz energia renovável.
                </p>
                <p>
                  <b>2.</b> A energia gerada é contabilizada como créditos para
                  o seu consumo via concessionária.
                </p>
                <p>
                  <b>3.</b> Você economiza e ainda utiliza uma fonte de energia
                  limpa!
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="hr"></div>
            </div>
          </div>
        </div>
      </section>
      {/* ***** Features Small End ******/}

      {/* ***** Welcome Area Start ******/}
      <section className="section" id="about2">
        <div className="container">
          <div className="row">
            <div className="left-text col-lg-6 col-md-12 col-sm-12 mobile-bottom-fix">
              <div className="left-heading">
                <h5>Cooperar para prosperar</h5>
              </div>
              <h6 className="mb-4">
                <b>As pessoas são a nossa base.</b>
              </h6>
              <p>
                Fazemos a ponte entre produtores de energia verde e consumidores
                em um modelo ganha-ganha que{' '}
                <b>beneficia o investidor e o consumidor</b>, gera empregos e
                reduz emissões de CO2 no meio ambiente.
              </p>
              <ul>
                <li>
                  <img src="assets/images/créditos-de-energia.png" alt="" />
                  <div className="text">
                    <h6>Créditos de energia:</h6>
                    <p>
                      Os créditos de energia são inseridos diretamente na conta
                      de energia.
                    </p>
                  </div>
                </li>
                <li>
                  <img src="assets/images/economia.png" alt="" />
                  <div className="text">
                    <h6>Economia:</h6>
                    <p>O desconto de até 20% na sua conta de energia.</p>
                  </div>
                </li>
                <li>
                  <img src="assets/images/sustentabilidade.png" alt="" />
                  <div className="text">
                    <h6>Sustentabilidade:</h6>
                    <p>
                      Você utiliza energia limpa e contribui para os objetivos
                      do desenvolvimento sustentável da ONU, em particular o{' '}
                      <b>ODS 7: Energia limpa e acessível.</b>
                    </p>
                  </div>
                </li>
              </ul>
              <p>
                <small>
                  *O desconto inicia a partir de 30 dias após a adesão, a
                  depender dos prazos da concessionária de energia e
                  disponibilidade das fazendas solares.
                </small>
              </p>
            </div>
            <div className="right-image col-lg-6 col-md-12 col-sm-12 mobile-bottom-fix-big">
              <img
                src="assets/images/right-image.png"
                className="rounded img-fluid d-block mx-auto"
                alt="App"
              />
            </div>
          </div>
        </div>
      </section>
      {/* ***** Features Small End ******/}

      {/* ***** Features Small Start ******/}
      <section className="section" id="services">
        <div className="container">
          <div className="row">
            <div className="owl-carousel owl-theme">
              <div className="item service-item">
                <div className="icon">
                  <i className="icon fa fa-solar-panel"></i>
                </div>
                <h5 className="service-title">Energia renovável</h5>
                <p>Fonte de energia limpa e sustentável.</p>
              </div>
              <div className="item service-item">
                <div className="icon">
                  <i className="icon fa fa-money-check-dollar"></i>
                </div>
                <h5 className="service-title">Economia na conta de energia</h5>
                <p>Desconto de até 20% em sua tarifa.</p>
              </div>
              <div className="item service-item">
                <div className="icon">
                  <i className="icon fa-brands fa-creative-commons-zero"></i>
                </div>
                <h5 className="service-title">Adesão gratuita</h5>
                <p>Assinatura fácil, rápida e sem custo.</p>
              </div>
              <div className="item service-item">
                <div className="icon">
                  <i className="icon fa fa-hammer"></i>
                </div>
                <h5 className="service-title">Não necessita de obras</h5>
                <p>Sem investimento e instalação de painéis.</p>
              </div>
              <div className="item service-item">
                <div className="icon">
                  <i className="fa-solid fa-seedling"></i>
                </div>
                <h5 className="service-title">Preservação do meio ambiente</h5>
                <p>Energia verde faz bem para o mundo.</p>
              </div>
              <div className="item service-item">
                <div className="icon">
                  <i className="icon fa fa-paste"></i>
                </div>
                <h5 className="service-title">Sem burocracias</h5>
                <p>Não se preocupe! A gente cuida dessa parte.</p>
              </div>
              <div className="item service-item">
                <div className="icon">
                  <i className="icon fa fa-handshake"></i>
                </div>
                <h5 className="service-title">Ser parte do negócio</h5>
                <p>Democratizamos o acesso a energias renováveis.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ***** Features Small End ******/}

      {/* ***** Frequently Question Start ******/}
      <section className="section" id="frequently-question">
        <div className="container">
          {/* ***** Section Title Start ******/}
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading">
                <h2>Dúvidas frequentes</h2>
              </div>
            </div>
            <div className="offset-lg-3 col-lg-6">
              <div className="section-heading">
                <p>
                  Essa área de dúvidas frequentes foi pensada para esclarecer as
                  questões mais comuns apresentadas por nossos clientes.
                </p>
              </div>
            </div>
          </div>
          {/* ***** Section Title End ******/}

          <div className="row">
            <div className="left-text col-lg-5 col-md-6 col-sm-12">
              <h5>Confira as dúvidas mais frequentes</h5>
              <div className="accordion-text">
                <p>
                  Pensando em melhor atendê-lo, deixamos nesta seção as
                  perguntas que mais recebemos de nossos clientes e parceiros.
                  Veja ao lado se sua dúvida já foi respondida, e caso prefira,
                  estamos à disposição para te atender por telefone, e-mail ou
                  whatsapp.
                </p>
                <span>
                  <a href="mailto:contato@icoop.com.br">
                    <i className="fa-solid fa-envelope"></i>
                    &nbsp;&nbsp; contato@icoop.com.br
                  </a>
                </span>
                <span>
                  <a href="https://wa.me/55041991684676">
                    <i className="fa-brands fa-whatsapp"></i>
                    &nbsp;&nbsp; (41) 99168-4676
                  </a>
                </span>
                <a href="#contact-us" className="main-button">
                  Entre em contato
                </a>
              </div>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12">
              <div className="accordions is-first-expanded">
                <article className="accordion">
                  <div className="accordion-head">
                    <span>A energia por assinatura é confiável?</span>
                    <span className="icon">
                      <i className="icon fa fa-chevron-right"></i>
                    </span>
                  </div>
                  <div className="accordion-body">
                    <div className="content">
                      <p>
                        Sim! A energia por assinatura é um processo de aluguel
                        de usina feito 100% online, e regulamentado pela
                        resolução 482 da ANEEL e Lei 14.300/2022. Todo o
                        processo é 100% digital e nossa equipe está sempre à
                        disposição para dar assistência e tirar dúvidas.
                      </p>
                    </div>
                  </div>
                </article>
                <article className="accordion">
                  <div className="accordion-head">
                    <span>
                      Como consigo desconto na minha conta de energia?
                    </span>
                    <span className="icon">
                      <i className="icon fa fa-chevron-right"></i>
                    </span>
                  </div>
                  <div className="accordion-body">
                    <div className="content">
                      <p>
                        A energia solar gerada pela I-COOP é mais barata que a
                        energia gerada pelas distribuidoras convencionais.
                        Trabalhamos com o modelo de geração distribuída, que
                        injeta os créditos produzidos em nossas fazendas solares
                        diretamente na fatura da sua concessionária de energia
                        local. O que pode representar até 20% de desconto sobre
                        sua tarifa de energia.
                      </p>
                    </div>
                  </div>
                </article>
                <article className="accordion">
                  <div className="accordion-head">
                    <span>Como é feita a adesão?</span>
                    <span className="icon">
                      <i className="icon fa fa-chevron-right"></i>
                    </span>
                  </div>
                  <div className="accordion-body">
                    <div className="content">
                      <p>
                        Sua adesão será rápida, segura e 100% on-line, com o
                        termo de adesão assinado digitalmente, sem você precisar
                        se deslocar até a I-COOP.
                      </p>
                    </div>
                  </div>
                </article>
                <article className="accordion">
                  <div className="accordion-head">
                    <span>Como funciona a entrega de energia?</span>
                    <span className="icon">
                      <i className="icon fa fa-chevron-right"></i>
                    </span>
                  </div>
                  <div className="accordion-body">
                    <div className="content">
                      <p>
                        Nós produzimos a energia a partir de fontes renováveis,
                        injetamos na rede da concessionária que por sua vez
                        distribui de acordo com o consumo entre as unidades
                        consumidoras pertencentes a cooperativa.
                      </p>
                    </div>
                  </div>
                </article>
                <article className="accordion">
                  <div className="accordion-head">
                    <span>O meu contrato tem fidelidade?</span>
                    <span className="icon">
                      <i className="icon fa fa-chevron-right"></i>
                    </span>
                  </div>
                  <div className="accordion-body">
                    <div className="content">
                      <p>
                        Não! Assim como outros serviços por assinatura, aqui na
                        I-COOP você pode cancelar a qualquer momento, basta
                        avisar com 90 dias de antecedência (tempo necessário
                        para os trâmites entre a I-COOP e a concessionária da
                        sua região).
                      </p>
                    </div>
                  </div>
                </article>
                <article className="accordion">
                  <div className="accordion-head">
                    <span>Qual o valor do investimento?</span>
                    <span className="icon">
                      <i className="icon fa fa-chevron-right"></i>
                    </span>
                  </div>
                  <div className="accordion-body">
                    <div className="content">
                      <p>
                        Zero! Aqui na I-COOP não temos taxa de adesão,
                        instalação ou manutenção. Você só paga o valor da
                        energia consumida.
                      </p>
                    </div>
                  </div>
                </article>
                <article className="accordion">
                  <div className="accordion-head">
                    <span>
                      Como fica a minha relação com a concessionária de energia
                      local?
                    </span>
                    <span className="icon">
                      <i className="icon fa fa-chevron-right"></i>
                    </span>
                  </div>
                  <div className="accordion-body">
                    <div className="content">
                      <p>
                        Não muda em nada! A sua distribuidora local (ex. Copel,
                        Eletropaulo, CPFL, EDP, etc.) vai continuar fornecendo a
                        energia normalmente. A diferença está no valor a ser
                        pago: as taxas mínimas vêm no boleto da concessionária e
                        o consumo de energia será pago para a I-COOP já com o
                        desconto aplicado. Ou seja, a única mudança é que a sua
                        fatura mensal será paga através de dois boletos.
                      </p>
                    </div>
                  </div>
                </article>
                <article className="accordion">
                  <div className="accordion-head">
                    <span>
                      Preciso fazer alguma alteração ou obra na minha estrutura?
                    </span>
                    <span className="icon">
                      <i className="icon fa fa-chevron-right"></i>
                    </span>
                  </div>
                  <div className="accordion-body">
                    <div className="content">
                      <p>
                        Fique tranquilo! Não é necessário fazer nenhuma
                        modificação nem aquisição. Todo o processo de injeção de
                        créditos é feito utilizando a rede da própria
                        concessionária de energia, inclusive todo o apontamento
                        referente ao consumo é feito pela concessionária.
                      </p>
                    </div>
                  </div>
                </article>
                <article className="accordion">
                  <div className="accordion-head">
                    <span>O que muda para mim?</span>
                    <span className="icon">
                      <i className="icon fa fa-chevron-right"></i>
                    </span>
                  </div>
                  <div className="accordion-body">
                    <div className="content">
                      <p>Como cliente I-COOP você irá:</p>
                      <ul>
                        <li>- Pagar mais barato pela sua energia;</li>
                        <li>
                          - Ao invés de um boleto, você terá dois (fatura
                          concessionária + boleto I-COOP), o valor final será
                          menor que no modelo em que você se encontra hoje;
                        </li>
                        <li>- Não precisa de obras e não tem fidelidade.</li>
                      </ul>
                    </div>
                  </div>
                </article>
                <article className="accordion">
                  <div className="accordion-head">
                    <span>
                      Vou precisar continuar pagando o boleto da concessionária?
                    </span>
                    <span className="icon">
                      <i className="icon fa fa-chevron-right"></i>
                    </span>
                  </div>
                  <div className="accordion-body">
                    <div className="content">
                      <p>
                        Sim! É muito importante que você pague a fatura da
                        concessionária, pois o fornecimento é feito através
                        dela.
                      </p>
                      <p>
                        Lembre-se que todo o processo de injeção de créditos é
                        feito através da concessionária e é ela também a
                        responsável por calcular, recolher os tributos e
                        repassar aos órgãos pertinentes. Portanto, você
                        continuará a receber as faturas da concessionária, porém
                        com os valores referentes somente aos tributos e consumo
                        mínimo.
                      </p>
                    </div>
                  </div>
                </article>
                <article className="accordion">
                  <div className="accordion-head">
                    <span>Se eu ficar sem energia, a quem devo recorrer?</span>
                    <span className="icon">
                      <i className="icon fa fa-chevron-right"></i>
                    </span>
                  </div>
                  <div className="accordion-body">
                    <div className="content">
                      <p>
                        A entrega da energia é responsabilidade da
                        concessionária/distribuidora, por isso, em caso de queda
                        de energia, entre em contato diretamente com a sua
                        concessionária local.
                      </p>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ***** Frequently Question End ******/}

      {/* ***** Contact Us Start ******/}
      <section className="section" id="contact-us">
        <div className="container-fluid">
          <div className="row">
            {/* ***** Contact Form Start ******/}
            <div className="col-lg-12 contact-form">
              <div className="container">
                <div className="row">
                  {/* ***** Contact Form Start ******/}
                  <div className="col-lg-12">
                    <form
                      id="contact"
                      action=""
                      method="post"
                      onSubmit={handleOnSubmit}
                    >
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <h2 className="mb-4">
                            <b>Solicitar proposta personalizada</b>
                          </h2>
                        </div>
                        <div className="col-md-12 col-sm-12">
                          <h6 className="mb-4">
                            <b>Informações de contato e dados</b>
                          </h6>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <FormInput
                            label={'Nome'}
                            name={'name'}
                            value={formData.name}
                            errors={formErrors}
                            onChange={(e) => handleOnChange(e, 'name')}
                            required
                          />
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <FormInput
                            label={'E-mail'}
                            name={'email'}
                            value={formData.email}
                            errors={formErrors}
                            onChange={(e) => handleOnChange(e, 'email')}
                            required
                          />
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <FormInput
                            label={'Telefone'}
                            name={'phone'}
                            value={formData.phone}
                            errors={formErrors}
                            mask={'(##) # ####-####'}
                            onChange={(e) => handleOnChange(e, 'phone')}
                            required
                          />
                        </div>
                        <div className="col-md-2 col-sm-12">
                          <fieldset>
                            <Form.Select
                              className="contact-field"
                              value={formData.isCompany ? 'pj' : 'pf'}
                              onChange={(e) =>
                                handleUpdateFormData(
                                  'isCompany',
                                  e.target.value === 'pj' ? true : false
                                )
                              }
                            >
                              <option value={'pf'}>Pessoa Física</option>
                              <option value={'pj'}>Pessoa Jurídica</option>
                            </Form.Select>
                          </fieldset>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <FormInput
                            label={formData.isCompany ? 'CNPJ' : 'CPF'}
                            name={'cnpjCpf'}
                            value={formData.cnpjCpf}
                            errors={formErrors}
                            mask={
                              formData.isCompany
                                ? '##.###.###/####-##'
                                : '###.###.###-##'
                            }
                            onChange={(e) => handleOnChange(e, 'cnpjCpf')}
                          />
                        </div>
                        {formData.isCompany && (
                          <div className="col-md-4 col-sm-12">
                            <FormInput
                              label={'Empresa'}
                              name={'company'}
                              value={formData.company}
                              errors={formErrors}
                              onChange={(e) => handleOnChange(e, 'company')}
                            />
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <h6 className="mb-4">
                            <b>Endereço</b>
                          </h6>
                        </div>
                        <div className="col-md-3 col-sm-12">
                          <FormInput
                            label={'CEP'}
                            name={'zipCode'}
                            value={formData.zipCode}
                            errors={formErrors}
                            mask={'#####-###'}
                            onChange={(e) => handleOnChange(e, 'zipCode')}
                          />
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <FormInput
                            label={'Endereço'}
                            name={'adress'}
                            value={formData.adress}
                            errors={formErrors}
                            onChange={(e) => handleOnChange(e, 'adress')}
                          />
                        </div>
                        <div className="col-md-2 col-sm-12">
                          <FormInput
                            label={'Número'}
                            name={'number'}
                            value={formData.number}
                            errors={formErrors}
                            onChange={(e) => handleOnChange(e, 'number')}
                          />
                        </div>
                        <div className="col-md-3 col-sm-12">
                          <FormInput
                            label={'Complemento'}
                            name={'complement'}
                            value={formData.complement}
                            errors={formErrors}
                            onChange={(e) => handleOnChange(e, 'complement')}
                          />
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <FormInput
                            label={'Bairro'}
                            name={'neighborhood'}
                            value={formData.neighborhood}
                            errors={formErrors}
                            onChange={(e) => handleOnChange(e, 'neighborhood')}
                          />
                        </div>
                        <div className="col-md-3 col-sm-12">
                          <FormInput
                            label={'Cidade'}
                            name={'city'}
                            value={formData.city}
                            errors={formErrors}
                            onChange={(e) => handleOnChange(e, 'city')}
                            required
                          />
                        </div>
                        <div className="col-md-2 col-sm-12">
                          <fieldset>
                            <Form.Select
                              className="contact-field"
                              value={formData.state?.uf}
                              onChange={(e) =>
                                handleSelectState(e.target.value)
                              }
                            >
                              {states.map((state, i) => {
                                return (
                                  <option key={i} value={state.uf}>
                                    {state.name}
                                  </option>
                                )
                              })}
                            </Form.Select>
                          </fieldset>
                        </div>
                        <div className="col-lg-12">
                          <fieldset>
                            <button
                              type="submit"
                              id="form-submit"
                              className="main-button"
                            >
                              Enviar
                            </button>
                          </fieldset>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          {showSuccess && (
                            <Alert variant={'success'}>{successMessage}</Alert>
                          )}
                          {showError && (
                            <Alert variant={'danger'}>{errorSendMessage}</Alert>
                          )}
                          {showvalidationError && (
                            <Alert variant={'danger'}>{errorMessage}</Alert>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* ***** Contact Form End ***** */}
          </div>
        </div>
      </section>
      {/* ***** Contact Us End ******/}

      {/* ***** Footer Start ******/}
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <p className="copyright">
                Copyright &copy; 2023 I-COOP Energia Renovável
              </p>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12">
              <ul className="social">
                <li>
                  <a href="https://wa.me/55041991684676">
                    <i className="fa-solid fa-envelope"></i>
                  </a>
                </li>
                <li>
                  <a href="https://wa.me/55041991684676">
                    <i className="fa-brands fa-whatsapp"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/icoop.energia/">
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </li>
                {/* <li>
                  <a href="#"><i className="fa fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i className="fa fa-linkedin"></i></a>
                </li>
                <li>
                  <a href="#"><i className="fa fa-rss"></i></a>
                </li>
                <li>
                  <a href="#"><i className="fa fa-dribbble"></i></a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
