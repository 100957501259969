import axios from "axios"
import { DefaultApiResponse, FormDataSendInterface } from "../types"

export const UseSendFormData = async (formData: FormDataSendInterface ): Promise<boolean> => {
  try {
    const { data, status } = await axios.post<DefaultApiResponse>(
      process.env.REACT_APP_API_URL || '',
      formData,
      {
        headers: {
          Accept: 'application/json',
        },
      }
    )

    console.log(JSON.stringify(data, null, 4))

    // 👇️ "response status is: 200"
    console.log('response status is: ', status)

    return status === 200 && data.success
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message)
      return false
    } else {
      console.log('unexpected error: ', error)
      return false
    }
  }
}
