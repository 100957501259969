// import React from 'react';

import { PatternFormat } from 'react-number-format'

export interface FormInputProps {
  name: string
  label: string
  value: string
  mask?: string
  required?: boolean
  errors?: { [key: string]: string }
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const FormInput = (props: FormInputProps) => {
  const { name, label, required = false, value, errors, onChange, mask } = props
  return (
    <fieldset>
      <div className="input-group has-validation">
        {mask && (
          <PatternFormat
            format={mask}
            mask="_"
            value={value}
            name={name}
            id={name}
            placeholder={`${label}${required ? ' *' : ''}`}
            allowEmptyFormatting={false}
            className={`contact-field form-control ${errors?.[name] ? 'is-invalid' : ''}`}
            onChange={(e) => onChange(e)}
          />
        )}
        {!mask && (
          <input
            name={name}
            type="text"
            id={name}
            placeholder={`${label}${required ? ' *' : ''}`}
            value={value}
            onChange={(e) => onChange(e)}
            className={`contact-field form-control ${errors?.[name] ? 'is-invalid' : ''}`}
          />
        )}
        {errors?.[name] && (
          <div className="invalid-feedback">{errors?.[name]}</div>
        )}
      </div>
    </fieldset>
  )
}
